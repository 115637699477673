import React from "react";
import BaseLayout from "../../components/layouts/baseLayout";
import TextJumbotron from "../../components/util/textJumbotron";
import FeaturedTutoring from "../../components/home/featuredTutoring";
import TutoringHighlights from "../../components/home/tutoringHighlights";
import TutoringClassesOffered from "../../components/home/tutoringClassesOffered";

export default function HowItWorksPage({ data }) {
  return (
    <BaseLayout pageTitle="Tutoring: How It Works">
      <TextJumbotron heading="How It Works" />
      <section>
        <FeaturedTutoring />
        <TutoringClassesOffered />
        <TutoringHighlights />
      </section>
    </BaseLayout>
  );
}
